import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Grid from "../../../../../components/grid"
import Heading from "../../../../../components/heading"
import Image from "../../../../../components/image"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "reisetagebuch",
}

const Page = () => {
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  const data = useStaticQuery(graphql`
    query Reisetagebuch1Query {
      tagebuch: file(relativePath: { eq: "kurse/tagebuch-seite-1.png" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/reisetagebuch/ortsnamen" />
      }
      navigation={navigation}
      progress={progress}
    >
      <Seo title="Orte markieren" />
      <Stack>
        <Heading as="h2" level={2}>
          Orte markieren
        </Heading>
        <Grid columns={[1, 2]}>
          <Stack>
            <Paragraph>
              Nun kannst du dir vorstellen, wie Hölderlin aussah, als er auf
              Reisen ging. Hölderlin war ziemlich sportlich. Er konnte
              problemlos 50 Kilometer am Stück zu Fuß gehen. Weite Strecken
              legte er mit dem Pferd oder der Kutsche zurück.
            </Paragraph>
            <Paragraph>
              Bei seiner ersten größeren Reise hat er seine Cousine Friederike
              und ihren Verlobten Johann Friedrich Blum in Speyer besucht. Dabei
              hat er ein Tagebuch geführt, um seiner Mutter alles zu berichten.
              Damals gab es nämlich noch keine Handys. Es gab noch nicht einmal
              Telefone. Außerdem hat Hölderlin genau aufgeschrieben, für was er
              Geld ausgegeben hat. Seine Mutter war nämlich sehr sparsam.
            </Paragraph>
          </Stack>
          <Image
            image={data.tagebuch.childImageSharp.gatsbyImageData}
            alt="Tagebuch"
            caption="Eine Seite aus Hölderlins Tagebuch"
          />
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
